<template>
  <div class="recruit">
    <template v-if="recruitList && recruitList.length">
      <p class="recruit-title">公司招聘岗位介绍</p>
      <div v-for="(item, idx) in recruitList" :key="item.id" class="recruit-item">
        <p class="recruit-item__title">{{ toChinesNum(idx + 1) + '、' + item.title }}</p>
        <div class="recruit-item__content">
          <div v-html="item.content"></div>
        </div>
      </div>
    </template>
    <div v-else key="no-recruit-data" class="no-data">
      <img src="@images/icon/no-data.png" class="no-img" />
      <p class="no-text">暂无招聘信息~</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      recruitList: [],
    };
  },
  methods: {
    /**
     * 数字转成汉字
     * @params num === 要转换的数字
     * @return 汉字
     * */
    toChinesNum(num) {
      let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      let unit = ['', '十', '百', '千', '万'];
      num = parseInt(num);
      let getWan = (temp) => {
        let strArr = temp.toString().split('').reverse();
        let newNum = '';
        let newArr = [];
        strArr.forEach((item, index) => {
          newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index]);
        });
        let numArr = [];
        newArr.forEach((m, n) => {
          if (m !== '零') numArr.push(n);
        });
        if (newArr.length > 1) {
          newArr.forEach((m, n) => {
            if (newArr[newArr.length - 1] === '零') {
              if (n <= numArr[numArr.length - 1]) {
                newNum += m;
              }
            } else {
              newNum += m;
            }
          });
        } else {
          newNum = newArr[0];
        }

        return newNum;
      };
      let overWan = Math.floor(num / 10000);
      let noWan = num % 10000;
      if (noWan.toString().length < 4) {
        noWan = '0' + noWan;
      }
      return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num);
    },
  },
  async mounted() {
    this.recruitList = await this.$api.recruitQueryRecruitList({});
  },
};
</script>

<style scoped lang="scss">
.recruit {
  margin: 140px;
  text-align: left;
  min-height: 400px;

  &-title {
    font-size: 40px;
    font-weight: bold;
  }

  &-item {
    margin: 40px 0;
    &__title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 14px;
    }
    &__phone {
      margin-top: 20px;
    }
    &__content {
      color: #62656a !important;
    }
  }
  .no-data {
    padding: 180px 0;
    display: grid;
    justify-content: center;
    .no-img {
      width: 120px;
      height: 120px;
    }
    .no-text {
      color: #999;
      margin-top: 40px;
      font-size: 18px;
    }
  }
}

.mobile {
  .recruit {
    margin: 90px 14px 40px;
    &-title {
      font-size: 24px;
    }
    &-item {
      margin: 20px 0;
      &__title {
        margin-bottom: 14px;
      }
      ::v-deep img {
        max-width: 100% !important;
        display: block !important;
      }
    }
  }
}
</style>
